import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ElementLoader from 'erpcore/components/ElementLoader';

import {
    getImage,
    getImageFetchingData,
    getImageUrl
} from 'erpcore/components/ImageManager/ImageManager.selectors';
import { actions as imageManagerActions } from 'erpcore/components/ImageManager/ImageManager.reducer';

const Image = ({
    iri,
    version,
    width,
    height,
    alt,
    className,
    fallback,
    isContainer,
    children,
    onClick
}) => {
    const dispatch = useDispatch();

    const image = useSelector(state => getImage(state, iri));

    const imageUrl = useSelector(state => getImageUrl(state, iri, version));

    const imageFetchingData = useSelector(state => getImageFetchingData(state));

    const fetchImage = () => {
        return new Promise((resolve, reject) => {
            let fetchNeeded = false;

            if (iri && !imageFetchingData?.[iri]) {
                if (image) {
                    if (image?.iri !== iri) {
                        fetchNeeded = true;
                    }
                } else {
                    fetchNeeded = true;
                }
            }

            if (fetchNeeded) {
                dispatch({
                    promise: { resolve, reject },
                    type: imageManagerActions.START_FETCHING_IMAGE,
                    iri
                });
            }
        });
    };

    useEffect(() => {
        if (iri) {
            fetchImage(iri);
        }
    }, [iri]);

    if (imageUrl) {
        if (isContainer)
            return (
                <div
                    onClick={onClick}
                    onKeyDown={onClick}
                    role="button"
                    tabIndex={0}
                    className={className}
                    width={width}
                    height={height}
                    style={{
                        backgroundImage: `url(${imageUrl})`
                    }}
                >
                    {children}
                </div>
            );
        return <img className={className} src={imageUrl} alt={alt} width={width} height={height} />;
    }

    return fallback;
};

Image.defaultProps = {
    iri: null,
    version: null,
    width: 100,
    height: 100,
    alt: 'alt',
    className: null,
    fallback: <ElementLoader />,
    isContainer: false,
    children: null,
    onClick: () => {}
};
Image.propTypes = {
    iri: PropTypes.string,
    version: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string,
    className: PropTypes.string,
    fallback: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.node
    ]),
    isContainer: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.node
    ]),
    onClick: PropTypes.func
};

export default Image;
