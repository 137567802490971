import { createSelector } from 'reselect';

export const getFetchingMe = state => state.authManager?.fetchingMe;

export const getSignedIn = state => state.authManager.isSignedIn;

export const getMeData = state => state?.authManager?.user || {};

export const getIsAppraiser = state => state?.authManager?.user?._type === 'appraiser' || false;

export const getIsRealtor = state => state?.authManager?.user?._type === 'realtor' || false;

export const getUserToken = state => state?.authManager?.token || false;

export const getUserImpersonated = state => state?.authManager?.impersonated || false;

export const getUserOriginalToken = state => state?.authManager?.originalToken || '';

export const getTwoFAData = state => state?.authManager?.twoFA;

export const getUserPermissions = state => {
    if (
        state &&
        state.authManager &&
        state.authManager.user &&
        state.authManager.user.meta &&
        state.authManager.user.meta.permissions &&
        state.authManager.user.meta.permissions.constructor === Object
    ) {
        return state.authManager.user.meta.permissions;
    }
    return {
        events: false,
        vendors: false,
        eventsPerPermission: {}
    };
};

export const getUserCurrentOrganization = state =>
    state.authManager.userOrganization && state.authManager.userOrganization;

export const getUserRole = state =>
    state.authManager.isSignedIn === true
        ? state.authManager.user && state.authManager.user.role
        : '';

export default createSelector([
    getSignedIn,
    getMeData,
    getUserRole,
    getUserCurrentOrganization,
    getTwoFAData
]);
