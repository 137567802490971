import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import FloatingNotifications from 'erpcore/components/Layout/FloatingNotifications';
import Image from 'erpcore/components/Image';
import PortalFooter from 'erpcore/components/Layout/PortalFooter';
import RequestHelp from 'erpcore/components/RequestHelp';
import './PortalFrontMain.scss';

const PortalFrontMain = ({ children, className }) => {
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const footerText = organizationSettings?.settings?.portalFooterDisclaimer;
    return (
        <>
            <main className={`portal-front-main ${className || ''}`}>
                <div className="portal-front-main__logo-wrapper">
                    <Image
                        height="auto"
                        width="auto"
                        iri={organizationSettings?.settings?.portalLogo}
                        className="portal-front-main__logo"
                    />
                </div>
                {children}
                <PortalFooter>
                    <p>{footerText}</p>
                </PortalFooter>
                <RequestHelp />
            </main>
            <div className="invisible-fix-animation" />
            <FloatingNotifications />
        </>
    );
};

PortalFrontMain.defaultProps = {
    children: null,
    className: ''
};

PortalFrontMain.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

export default PortalFrontMain;
