import Sidebar from 'erpcore/components/Layout/Sidebar';
import MainNavigation from 'erpcore/components/Layout/MainNavigation';
import PortalMain from 'erpcore/components/Layout/PortalMain';

const defaultLayout = {
    data: [
        {
            slotName: 'leftSidebar',
            component: Sidebar,
            position: 0,
            content: {
                data: [
                    {
                        slotName: 'mainNavigation',
                        component: MainNavigation,
                        position: 1
                    }
                ]
            }
        },
        {
            slotName: 'main',
            component: PortalMain,
            position: 0
        }
    ]
};

export default defaultLayout;
