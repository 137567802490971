import PortalFrontMain from 'erpcore/components/Layout/PortalFrontMain';

const frontLayout = {
    data: [
        {
            slotName: 'main',
            component: PortalFrontMain,
            position: 0
        }
    ]
};

export default frontLayout;
