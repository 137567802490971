import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import gallerySaga from 'erpcore/components/Gallery/Gallery.saga';
import organizationSettingsSaga from 'erpcore/utils/OrganizationSettings/OrganizationSettings.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';
import { forgotPasswordSaga } from 'erpcore/screens/ForgotPassword/ForgotPassword.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...listingSaga]);
    yield all([...gallerySaga]);
    yield all([...organizationSettingsSaga]);
    yield all([...imageManagerSaga]);
    yield all([...forgotPasswordSaga]);
}
