import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import Image from 'erpcore/components/Image';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import './MainNavigation.scss';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const MainNavigation = () => {
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};

    return (
        <Menu id="main-navigation">
            <Link to="/" className="main-navigation__logo">
                <Image iri={organizationSettings?.settings?.portalLogo} />
            </Link>
        </Menu>
    );
};

export default MainNavigation;
