import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import ForgotPasswordForm from 'erpcore/screens/ForgotPassword/components/ForgotPasswordForm';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import ResendResetPassword from 'erpcore/screens/ForgotPassword/components/ResendResetPassword';

import LayoutManager from 'erpcore/utils/LayoutManager';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';
import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const title = 'Reset Password';

    const nextStep = () => {
        setStep(step + 1);
    };

    const onSubmitSignInForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_FORGOT_PASSWORD,
                formData
            })
        )
            .then(() => {
                nextStep();
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <HeadMeta title={title} />
            {/* Forgot Password */}
            <div className="portal-sign-in">
                {step === 1 && (
                    <>
                        <h2 className="front-main__title">Reset your password</h2>
                        <p className="front-main__subtitle">
                            Enter your email and we&apos;ll send you a password reset email.
                        </p>
                        <ForgotPasswordForm nextStep={nextStep} onSubmit={onSubmitSignInForm} />
                        <PageNotifications />
                    </>
                )}
                {/* Resend Reset Password */}
                {step === 2 && <ResendResetPassword onSubmit="" />}
            </div>
        </LayoutManager>
    );
};

export default ForgotPassword;
