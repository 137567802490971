import React from 'react';
import PropTypes from 'prop-types';
import './PortalFooter.scss';

const PortalFooter = ({ className, children }) => {
    return <footer className={`portal-footer ${className || ''}`}>{children}</footer>;
};

PortalFooter.defaultProps = {
    className: null,
    children: null
};

PortalFooter.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default PortalFooter;
