import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Email } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    component={Email}
                    validate={valueValidation([{ validator: 'email' }, { validator: 'required' }])}
                />
            </Form.Row>
            <label htmlFor="amex" style={{ display: 'none' }}>
                AMEX
            </label>
            <input type="hidden" name="amex" required="" value="" id="amex" />
            <Form.Row verticalCenter>
                <Form.RowColResponsive>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label="Send"
                    />
                </Form.RowColResponsive>
            </Form.Row>
            <Form.Row>
                <Link to="/">Back to sign in?</Link>
            </Form.Row>
        </Form>
    );
};

ForgotPasswordForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false
};

ForgotPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'ForgotPasswordForm',
    enableReinitialize: true
})(ForgotPasswordForm);
