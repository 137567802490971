import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LayoutManager from 'erpcore/utils/LayoutManager';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';

import ResetPasswordForm from 'erpcore/screens/ForgotPassword/components/ResetPasswordForm';

import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import PageLoader from 'erpcore/components/PageLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const ResetPassword = ({ match }) => {
    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    const isLoginInProcess = location.pathname === '/reset-password-login';

    const title = 'Reset Password';

    const signIn = token => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN_WITH_TOKEN,
                token
            })
        );
    };

    const onSubmitResetPasswordForm = formData => {
        formData = Object.assign({}, formData, { reset_token: match.params.token });

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_RESET_PASSWORD,
                formData
            })
        )
            .then(response => {
                history.push('/reset-password-login');
                signIn(response?.token)
                    .then(() => {
                        history.push('/');
                    })
                    .catch(error => {
                        return error;
                    });
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <div className="portal-sign-in">
                <h2 className="front-main__title">Setup your new password</h2>

                {!isLoginInProcess && <ResetPasswordForm onSubmit={onSubmitResetPasswordForm} />}

                {!!isLoginInProcess && <PageLoader />}

                <PageNotifications />
            </div>
        </LayoutManager>
    );
};

ResetPassword.defaultProps = {
    match: null
};

ResetPassword.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ResetPassword);
