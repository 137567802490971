import React from 'react';
import * as Scroll from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import './MobileMenu.scss';
import Svg from 'erpcore/components/Svg';
import DealsSelect from 'erpcore/components/Layout/MainNavigation/components/DealsSelect';
import { actions as layoutManagerActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import { getIsAppraiser } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const MobileMenu = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const isAppraiser = useSelector(getIsAppraiser);

    const updateSidebarDeckState = value => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: layoutManagerActions.UPDATE_SIDEBAR_DECK_STATE,
                response: value
            })
        ).catch(error => {
            return console.error(error);
        });
    };

    return (
        <div className="mobile-menu">
            <DealsSelect onChange={() => updateSidebarDeckState(false)} />
            <NavLink
                to={`/property/${params?.project}/${params?.dealID}`}
                onClick={() => {
                    updateSidebarDeckState(false);
                    setTimeout(
                        () =>
                            Scroll.animateScroll.scrollToTop({
                                offset: -100,
                                delay: 100,
                                smooth: true
                            }),
                        300
                    );
                }}
                className="mobile-menu__item"
            >
                <Svg icon="file" className="mobile-menu__item-icon" />
                <span className="mobile-menu__item-link">Dashboard</span>
            </NavLink>
            <NavLink
                to={`/property/${params?.project}/${params?.dealID}/key-contacts`}
                className="mobile-menu__item"
                onClick={() => {
                    updateSidebarDeckState(false);
                }}
            >
                <Svg icon="approve" className="mobile-menu__item-icon" />
                <span className="mobile-menu__item-link">Key Contacts</span>
            </NavLink>
            {!isAppraiser && (
                <NavLink
                    to={`/property/${params?.project}/${params?.dealID}/faq`}
                    className="mobile-menu__item"
                    onClick={() => {
                        updateSidebarDeckState(false);
                    }}
                >
                    <Svg icon="help" className="mobile-menu__item-icon" />
                    <span className="mobile-menu__item-link">Frequently Asked Questions</span>
                </NavLink>
            )}
            <NavLink
                to={`/property/${params?.project}/${params?.dealID}/my-profile`}
                className="mobile-menu__item"
                onClick={() => {
                    updateSidebarDeckState(false);
                }}
            >
                <Svg icon="profile" className="mobile-menu__item-icon" />
                <span className="mobile-menu__item-link">My Profile</span>
            </NavLink>
            <NavLink to="/sign-out" className="mobile-menu__item">
                <Svg icon="signOut" className="mobile-menu__item-icon" />
                <span className="mobile-menu__item-link">Logout</span>
            </NavLink>
        </div>
    );
};

MobileMenu.defaultProps = {};

MobileMenu.propTypes = {};

export default MobileMenu;
