import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const TwoFactorForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="code"
                    id="code"
                    fieldProps={{
                        label: 'Two Factor Code',
                        clearable: true
                    }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row verticalCenter>
                <Button submit disabled={pristine || invalid} loading={submitting} label="VERIFY" />
            </Form.Row>
        </Form>
    );
};

TwoFactorForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false
};

TwoFactorForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'TwoFactorForm',
    enableReinitialize: true
})(TwoFactorForm);
