import { takeLatest, put, call, select } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import restClient from 'erpcore/api/restClient';
import { setToken, clearToken } from 'erpcore/api';
import dto from 'erpcore/utils/dto';
import { getTwoFAData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as authActions } from './AuthManager.reducer';

/**
 * /me enpoint params
 */
const meParams = {
    include: 'organization,organizations,image,country,state,city'
};

/**
 * Sign In
 * @param  {Object} credentials Email and Password
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
// eslint-disable-next-line consistent-return
export function* signIn({ credentials, promise, history }) {
    try {
        const singIn = yield restClient.post('/login', credentials);
        // store token
        const token = jwt_decode(singIn?.data?.token || '');
        if (token['2fa_done'] === false) {
            yield put({
                type: authActions.STORE_TWOFA,
                response: credentials
            });
            history.push('/2fa');
            return promise.resolve();
        }
        yield setToken(singIn?.data?.token);

        yield put({
            promise,
            type: authActions.START_FETCHING_ME,
            history
        });

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: singIn?.data?.token
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        // yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.SIGN_IN_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Into App With Token
 * @param  {boolean} isProspect
 * @param  {string} token
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* signInWithToken({ token, promise }) {
    try {
        // store token
        yield setToken(token);

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: token
        });

        yield put({
            promise,
            type: authActions.START_FETCHING_ME
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.SIGN_IN_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Out Saga
 */
export function* signOut({ promise }) {
    try {
        clearToken();
        // This action is handled in main reducer file where it resets the redux store
        yield put({ type: authActions.SIGN_OUT_SUCCESSFUL });
        if (yield !!promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield console.error(error);
        if (yield !!promise) {
            yield call(promise.reject);
        }
    }
}

/**
 * Fetch User Data
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* fetchMe({ promise, history, impersonateToken }) {
    // Get user data
    try {
        if (impersonateToken)
            restClient.defaults.headers.Authorization = `Bearer ${impersonateToken}`;
        const getMe = yield restClient.get('/api/me', { params: meParams });
        yield put({
            type: authActions.FETCHING_ME_SUCCESSFULL
        });

        // Fetch My Permissions
        yield put({
            type: authActions.START_FETCHING_ME_PERMISSIONS
        });
        try {
            const getMePermissions = yield restClient.get('/api/me/permissions');

            yield put({
                type: authActions.FETCHING_ME_PERMISSIONS_SUCCESSFULL
            });

            getMe.data.data.attributes.userPermissions =
                getMePermissions?.data?.data?.attributes?.permissions || [];

            yield put({ type: authActions.STORE_USER_DATA, response: dto(getMe?.data) });

            const getDeals = yield restClient.get('/api/deals', {
                params: {
                    include: 'project'
                }
            });

            if (getDeals?.data?.data?.length === 1) {
                const dtoDeals = dto(getDeals?.data);
                if (history && dtoDeals?.data[0]?.project?.slug && dtoDeals?.data[0]?.id)
                    history.push(
                        `/property/${dtoDeals?.data[0]?.project?.slug}/${dtoDeals?.data[0]?.id}`
                    );
            }

            yield put({
                type: authActions.STORE_TWOFA,
                response: null
            });

            if (promise) yield call(promise.resolve);
        } catch (error) {
            if (impersonateToken) yield call(promise.resolve);
            yield put({
                type: authActions.FETCHING_ME_PERMISSIONS_FAILED
            });
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: error?.response?.data || error
            });
            if (promise) yield call(promise.reject, error?.response?.data || error);
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.FETCHING_ME_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* verifyTWOFA({ promise, formData, history }) {
    try {
        const twofa = yield select(getTwoFAData);
        const singIn = yield restClient.post('/2fa-auth', {
            email: twofa?.email,
            token: formData?.code
        });

        yield setToken(singIn?.data?.token);

        yield put({
            promise,
            type: authActions.START_FETCHING_ME,
            history
        });

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: singIn?.data?.token
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        yield put({
            type: authActions.VERIFY_TWOFA_SUCCESSFULL,
            response: singIn?.data?.token
        });

        // yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.VERIFY_TWOFA_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const authManagerSaga = [
    takeLatest(authActions.START_SIGN_IN, signIn),
    takeLatest(authActions.START_SIGN_IN_WITH_TOKEN, signInWithToken),
    takeLatest(authActions.START_SIGN_OUT, signOut),
    takeLatest(authActions.START_FETCHING_ME, fetchMe),
    takeLatest(authActions.VERIFY_TWOFA, verifyTWOFA)
];
