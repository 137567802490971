import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';
import TwoFactorForm from 'erpcore/screens/Portal/screens/Portal2FA/components/TwoFactorForm';

import LayoutManager from 'erpcore/utils/LayoutManager';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import './Portal2FA.scss';
import restClient from 'erpcore/api/restClient';
import { getTwoFAData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const Portal2FA = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { state } = { ...location };
    const twoFA = useSelector(getTwoFAData);
    const title = '2FA';

    const resendMail = () => {
        restClient.post('/login', twoFA);
        dispatch({
            type: 'ADD_FLOATING_NOTIFICATION',
            response: { code: 'resend.twofa.ok' }
        });
    };

    const onSubmitForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.VERIFY_TWOFA,
                formData,
                history
            })
        )
            .then(() => {
                // If user was redirected to Sign In screen
                // After successful Sign In, redirect user to intended route
                if (state?.from?.pathname !== '/sign-out') {
                    history.replace(state?.from);
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <p className="portal-2fa__subtitle">
                You have received an email which contains two factor code.
                <br />
                If you haven’t received it, click{' '}
                <Button onClick={() => resendMail()} variation="link" label="here" />.
            </p>
            <div className="portal-2fa">
                <TwoFactorForm onSubmit={onSubmitForm} />
            </div>
        </LayoutManager>
    );
};

export default Portal2FA;
