import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as Scroll from 'react-scroll';

import Tooltip from 'erpcore/components/Tooltip';
import Svg from 'erpcore/components/Svg';
import DealsSelect from 'erpcore/components/Layout/MainNavigation/components/DealsSelect';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { getSidebarShowDeck } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import { actions as layoutManagerActions } from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import { getIsAppraiser } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import MobileMenu from './components/MobileMenu/MobileMenu';
import './Sidebar.scss';

const Sidebar = ({ className, layout }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const showDeck = useSelector(getSidebarShowDeck);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const isAppraiser = useSelector(getIsAppraiser);

    /**
     * Used in multiple functions to update Redux state value
     * @param {boolean} value
     */
    const updateSidebarDeckState = value => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: layoutManagerActions.UPDATE_SIDEBAR_DECK_STATE,
                response: value
            })
        ).catch(error => {
            return console.error(error);
        });
    };

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Set Deck to hidden/colsed on mobile
        const isMobile = window && window.innerWidth < 1030;
        if (isMobile) {
            updateSidebarDeckState(false);
        }
    }, []);

    /**
     * Used by the hamburger button
     */
    const handleDeck = () => {
        setShowProfileMenu(false);
        return updateSidebarDeckState(!showDeck);
    };

    return (
        <aside
            className={`sidebar ${showDeck ? 'sidebar--show-deck' : ''} ${
                showProfileMenu ? 'sidebar--profile-opened' : ''
            } ${className || ''}`}
        >
            <div className="sidebar__content">
                <MobileMenu />
                <DealsSelect />
                <div className="sidebar__bolt">
                    <ul className="sidebar__bolt-top">
                        <li className="sidebar__bolt-top-hamburger">
                            <button
                                type="button"
                                className="sidebar__button sidebar__button--hamburger"
                                onClick={() => handleDeck()}
                                onKeyPress={() => handleDeck()}
                            >
                                <Svg icon="menu" />
                            </button>
                        </li>
                        <li className="sidebar__bolt-menu-item sidebar__bolt-top-menu-icon--first">
                            <NavLink
                                to={`/property/${params?.project}/${params?.dealID}`}
                                exact
                                className="sidebar__button"
                                onClick={() =>
                                    setTimeout(
                                        () =>
                                            Scroll.animateScroll.scrollToTop({
                                                offset: -100,
                                                delay: 100,
                                                smooth: true
                                            }),
                                        300
                                    )
                                }
                            >
                                <Tooltip content="Dashboard">
                                    <Svg icon="file" />
                                </Tooltip>
                                <span className="sidebar__bolt-top-menu-icon-text">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="sidebar__bolt-menu-item">
                            <NavLink
                                to={`/property/${params?.project}/${params?.dealID}/key-contacts`}
                                className="sidebar__button"
                            >
                                <Tooltip content="Key Contacts">
                                    <Svg icon="approve" />
                                </Tooltip>
                                <span className="sidebar__bolt-top-menu-icon-text">
                                    Key Contacts
                                </span>
                            </NavLink>
                        </li>
                        {!isAppraiser && (
                            <li className="sidebar__bolt-menu-item">
                                <NavLink
                                    to={`/property/${params?.project}/${params?.dealID}/faq`}
                                    className="sidebar__button"
                                >
                                    <Tooltip content="Frequently Asked Questions">
                                        <Svg icon="help" />
                                    </Tooltip>
                                    <span className="sidebar__bolt-top-menu-icon-text">
                                        Frequently Asked Questions
                                    </span>
                                </NavLink>
                            </li>
                        )}
                        <li className="sidebar__bolt-menu-item">
                            <NavLink
                                to={`/property/${params?.project}/${params?.dealID}/my-profile`}
                                className="sidebar__button"
                            >
                                <Tooltip content="My Profile">
                                    <Svg icon="profile" />
                                </Tooltip>
                                <span className="sidebar__bolt-top-menu-icon-text">My Profile</span>
                            </NavLink>
                        </li>
                        <li className="sidebar__bolt-menu-item">
                            <NavLink to="/sign-out" className="sidebar__button">
                                <Tooltip content="Logout">
                                    <Svg icon="signOut" />
                                </Tooltip>
                                <span className="sidebar__bolt-top-menu-icon-text">Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="sidebar__deck">
                    <LayoutManager layout={layout} />
                </div>
            </div>
        </aside>
    );
};

Sidebar.defaultProps = {
    layout: null,
    className: ''
};

Sidebar.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    className: PropTypes.string
};

export default Sidebar;
