import React from 'react';
import './RequestHelp.scss';

const RequestHelp = () => {
    return (
        <p className="request-help">
            Having issues? Email us at{' '}
            <a href="mailto:customercare@mlacanada.com">customercare@mlacanada.com</a>
        </p>
    );
};

RequestHelp.defaultProps = {};

RequestHelp.propTypes = {};

export default RequestHelp;
