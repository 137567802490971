import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import FloatingNotifications from 'erpcore/components/Layout/FloatingNotifications';
import PortalFooter from 'erpcore/components/Layout/PortalFooter';
import RequestHelp from 'erpcore/components/RequestHelp';
import './PortalMain.scss';
import { getSidebarShowDeck } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';

const PortalMain = ({ children, className }) => {
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const footerText = organizationSettings?.settings?.portalFooterDisclaimer;
    const showDeck = useSelector(getSidebarShowDeck);
    return (
        <>
            <main
                className={`portal-main ${className || ''}${
                    showDeck ? ' portal-main--sidebar-opened' : ''
                }`}
            >
                <PageNotifications />
                {children}
                <PortalFooter>
                    <p>{footerText}</p>
                </PortalFooter>
                <RequestHelp />
            </main>
            <div className="invisible-fix-animation" />
            <FloatingNotifications />
        </>
    );
};

PortalMain.defaultProps = {
    children: null,
    className: ''
};

PortalMain.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string
};

export default PortalMain;
