import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import { actions as forgotPasswordActions } from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import { getForgotPasswordEmail } from 'erpcore/screens/ForgotPassword/ForgotPassword.selectors';

class ResendResetPasword extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { dispatch, email } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: forgotPasswordActions.START_RESEND_EMAIL_RESET_PASSWORD,
                email
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <Fragment>
                <h2 className="front-main__title">Check your inbox</h2>
                <p className="front-main__subtitle">Check your inbox to reset your password.</p>

                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <Form.Row verticalCenter>
                        <Form.RowColResponsive>
                            <Button submit loading={submitting} label="Resend reset password" />
                        </Form.RowColResponsive>
                    </Form.Row>
                </Form>

                <PageNotifications />
            </Fragment>
        );
    }
}

ResendResetPasword.defaultProps = {
    submitting: false,
    email: ''
};

ResendResetPasword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    email: PropTypes.string
};

const mapStateToProps = state => ({
    email: getForgotPasswordEmail(state)
});

ResendResetPasword = reduxForm({
    form: 'ResendResetPaswordForm',
    enableReinitialize: true
})(ResendResetPasword);

export default connect(mapStateToProps)(ResendResetPasword);
