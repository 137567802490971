import React, { useEffect, useRef, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from 'erpcore/components/PageLoader';
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn, getTwoFAData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import PortalSignIn from 'erpcore/screens/Portal/screens/PortalSignIn';
import Portal2FA from 'erpcore/screens/Portal/screens/Portal2FA';
import ResetPassword from 'erpcore/screens/ForgotPassword/screens/ResetPassword';
import ForgotPassword from 'erpcore/screens/ForgotPassword/screens/ForgotPassword';

const DealsListing = lazy(() => import('erpcore/screens/Portal/screens/DealsListing'));
const DealsSingle = lazy(() => import('erpcore/screens/Portal/screens/DealsSingle'));
const SignOut = lazy(() => import('erpcore/screens/SignOut'));
const Impersonate = lazy(() => import('erpcore/screens/Impersonate/screens/Impersonate'));
const ImpersonateClear = lazy(() => import('erpcore/screens/Impersonate/screens/ImpersonateClear'));

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const isSignedIn = useSelector(getSignedIn);
    const twofa = useSelector(getTwoFAData);
    const history = useHistory();

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        window.scrollTo(0, 0);
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamically updating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    useEffect(() => {
        const handlePostMessage = message => {
            if (message.origin.includes(enviromentVariables.REACT_APP_ERP_URL)) {
                if (message?.data?.impersonateToken)
                    localStorage.setItem('impersonateToken', message?.data?.impersonateToken);
            }
        };

        if (localStorage.getItem('impersonateToken')) {
            history.push(`/impersonate`);
        }

        window.addEventListener('message', handlePostMessage);
        return () => {
            window.removeEventListener('message', handlePostMessage);
        };
    }, []);

    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                {!isSignedIn && [
                    <Route key={PortalSignIn} exact path="/" component={PortalSignIn} />,
                    twofa ? (
                        <Route key={Portal2FA} exact path="/2fa" component={Portal2FA} />
                    ) : null,
                    twofa ? (
                        <Route key={SignOut} path="/sign-out" exact component={SignOut} />
                    ) : null
                ]}
                <Route key={Impersonate} path="/impersonate" exact component={Impersonate} />
                <Route key="UndefinedRedirect" path="/property/undefined/:dealID" exact>
                    <Redirect to="/" />
                </Route>
                {isSignedIn && [
                    <Route key={SignOut} path="/sign-out" exact component={SignOut} />,
                    <Route key={DealsListing} path="/" exact component={DealsListing} />,
                    <Route key={DealsListing} path="/:project" exact component={DealsListing} />,
                    <Route
                        key={DealsSingle}
                        path="/property/:project/:dealID"
                        component={DealsSingle}
                    />
                ]}
                {!isSignedIn && [
                    <Route exact path="/forgot-password" component={ForgotPassword} />,
                    <Route exact path="/reset-password/:token" component={ResetPassword} />,
                    <Route exact path="/reset-password-login" component={ResetPassword} />
                ]}
                <Route
                    key={ImpersonateClear}
                    path="/impersonate/clear"
                    exact
                    component={ImpersonateClear}
                />
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: location }
                    }}
                />
            </Switch>
        </Suspense>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
