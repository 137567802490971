import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing Reducers
// General Reducers
import { reducer as form } from 'redux-form';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import layoutManager from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import imageManager from 'erpcore/components/ImageManager/ImageManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import gallery from 'erpcore/components/Gallery/Gallery.reducer';
import listing from 'erpcore/components/Listing/Listing.reducer';
import organizationSettings from 'erpcore/utils/OrganizationSettings/OrganizationSettings.reducer';
import forgotPassword from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    authManager,
    routerManager,
    layoutManager,
    notificationManager,
    gallery,
    listing,
    form,
    organizationSettings,
    imageManager,
    forgotPassword
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        storage.removeItem('persist:root');
        const organizationSettingsCache = state?.organizationSettings?.organizationSettings;
        //  clearing out state
        //  filling out state with cached data
        state = {};
        state.organizationSettings = {
            organizationSettings: organizationSettingsCache
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
