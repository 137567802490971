import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import './DealsSelect.scss';
import Jsona from 'jsona';
import Svg from 'erpcore/components/Svg';
import restClient from 'erpcore/api/restClient';
import { useParams, NavLink } from 'react-router-dom';
import ElementLoader from 'erpcore/components/ElementLoader';
import InfiniteScroll from 'react-infinite-scroller';

function DealsSelect({ onChange }) {
    const params = useParams();
    const select = useRef();
    const dataFormatter = new Jsona();
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [deals, setDeals] = useState([]);
    const [dealsFetching, setDealsFetching] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        restClient
            .get(`/api/deals/${params?.dealID}`, {
                params: {
                    include: 'project,project.city,project.state,unit'
                }
            })
            .then(response => {
                setSelectedDeal(dataFormatter.deserialize(response.data));
            });
    }, [params?.dealID]);

    const handleClick = e => {
        if (select.current.contains(e.target)) {
            // inside click
            return;
        }
        setOpened(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const fetchDeals = page => {
        restClient
            .get('/api/deals', {
                params: {
                    include: 'project,project.city,project.state,unit',
                    page,
                    'filters[deleted][equals]': false,
                    'filters[avesdo_hash][exists]': 1,
                    'order_by[created_at]': 'DESC',
                    'filters[hidden_on_portal][equals]': false,
                    'filters[unit.listing_status][in]': ['Sold Firm']
                }
            })
            .then(response => {
                setDealsFetching(false);
                setDeals(uniqBy([...deals, ...dataFormatter.deserialize(response.data)], 'id'));
                if (response?.data?.data?.length < 10) {
                    setHasMore(false);
                }
            })
            .catch(() => {
                setDeals([]);
                setHasMore(false);
                setDealsFetching(false);
            });
    };

    useEffect(() => {
        fetchDeals();
    }, []);

    const selectedDealAvesdoMeta = selectedDeal?.unit?.meta?.avesdo;

    return (
        <div
            className={`deals-select ${deals?.length > 1 ? ' deals-select--hover' : ''}`}
            ref={select}
        >
            {dealsFetching && <ElementLoader overlay />}
            <button type="button" className="deals-select__deal" onClick={() => setOpened(!opened)}>
                {deals?.length > 1 && (
                    <Svg icon="arrowDown" className="deals-select__deal-arrow-down" />
                )}
                {selectedDeal?.project?.city?.name && (
                    <p className="deals-select__deal-info-location">
                        {selectedDeal?.project?.city?.name}
                    </p>
                )}
                <p className="deals-select__deal-info-title">{selectedDeal?.project?.name}</p>
                {selectedDealAvesdoMeta?.bedrooms ? (
                    <p className="deals-select__deal-info-value">
                        {selectedDealAvesdoMeta?.bedrooms} Bedrooms
                    </p>
                ) : (
                    ''
                )}
                {selectedDealAvesdoMeta?.bathrooms || selectedDealAvesdoMeta?.halfBathrooms ? (
                    <p className="deals-select__deal-info-value">
                        {selectedDealAvesdoMeta?.bathrooms +
                            selectedDealAvesdoMeta?.halfBathrooms * 0.5}{' '}
                        Bathrooms
                    </p>
                ) : (
                    ''
                )}
                {selectedDealAvesdoMeta?.listedPrice && (
                    <p className="deals-select__deal-info-value">
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'CAD',
                            currencyDisplay: 'narrowSymbol'
                        }).format(selectedDealAvesdoMeta?.listedPrice)}{' '}
                        CAD
                    </p>
                )}
            </button>
            {deals?.length > 1 && (
                <div
                    className={`deals-select__deals ${opened ? 'deals-select__deals--opened' : ''}`}
                >
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={fetchDeals}
                        initialLoad
                        hasMore={hasMore && !dealsFetching}
                        useWindow={false}
                        loader={
                            <div style={{ textAlign: 'center' }} key={0}>
                                Loading ...
                            </div>
                        }
                    >
                        {deals?.map(deal => {
                            const avesdoMeta = deal?.unit?.meta?.avesdo;
                            return (
                                <NavLink
                                    to={`/property/${deal?.project?.slug}/${
                                        deal?.id.split('/')[3]
                                    }`}
                                    className="deals-select__deal"
                                    onClick={() => {
                                        setOpened(false);
                                        window.scrollTo(0, 0);
                                        onChange();
                                    }}
                                    key={deal?.id}
                                >
                                    <Svg
                                        icon="arrowRight"
                                        className="deals-select__deal-arrow-right"
                                    />
                                    {deal?.project?.city?.name && (
                                        <p className="deals-select__deal-info-location">
                                            {deal?.project?.city?.name}
                                        </p>
                                    )}
                                    <p className="deals-select__deal-info-title">
                                        {deal?.project?.name}
                                    </p>
                                    {avesdoMeta?.bedrooms ? (
                                        <p className="deals-select__deal-info-value">
                                            {avesdoMeta?.bedrooms} Bedrooms
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {avesdoMeta?.bathrooms || avesdoMeta?.halfBathrooms ? (
                                        <p className="deals-select__deal-info-value">
                                            {avesdoMeta?.bathrooms +
                                                avesdoMeta?.halfBathrooms * 0.5}{' '}
                                            Bathrooms
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {avesdoMeta?.floorArea ? (
                                        <p className="deals-select__deal-info-value">
                                            {avesdoMeta?.floorArea} Sq Ft
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {avesdoMeta?.listedPrice ? (
                                        <p className="deals-select__deal-info-value">
                                            {Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'CAD',
                                                currencyDisplay: 'narrowSymbol'
                                            }).format(avesdoMeta?.listedPrice)}{' '}
                                            CAD
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </NavLink>
                            );
                        })}
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
}

DealsSelect.defaultProps = {
    onChange: () => {}
};

DealsSelect.propTypes = {
    onChange: PropTypes.func
};

export default DealsSelect;
