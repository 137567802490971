import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import SignInForm from 'erpcore/screens/Portal/screens/PortalSignIn/components/SignInForm';

import LayoutManager from 'erpcore/utils/LayoutManager';
import frontLayout from 'erpcore/utils/LayoutManager/frontLayout';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import './PortalSignIn.scss';

const SignIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { state } = { ...location };
    const title = 'Sign In';

    const onSubmitSignInForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_SIGN_IN,
                credentials: formData,
                history
            })
        )
            .then(() => {
                // If user was redirected to Sign In screen
                // After successful Sign In, redirect user to intended route
                if (state?.from?.pathname !== '/sign-out') {
                    history.replace(state?.from);
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager layout={frontLayout} slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <div className="portal-sign-in">
                <h2 className="portal-sign-in__title">Sign in</h2>
                <SignInForm onSubmit={onSubmitSignInForm} />
            </div>
        </LayoutManager>
    );
};

export default SignIn;
